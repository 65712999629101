import request from '@/utils/request';
export var defaultNotificationsData = {
  id: 0,
  enabled: true,
  title: '',
  message: '',
  image: null,
  link: null,
  page: null,
  params: null,
  sendToAll: false,
  appVersion: null,
  platform: null,
  stateId: null,
  state: {
    id: null
  },
  countryId: null,
  country: {
    id: null
  },
  cityId: null,
  city: {
    id: null
  }
};
export var getNotifications = function getNotifications(params) {
  return request({
    url: '/notifications',
    method: 'get',
    params: params
  });
};
export var getNotificationsById = function getNotificationsById(id) {
  return request({
    url: "/notifications/".concat(id),
    method: 'get'
  });
};
export var updateNotifications = function updateNotifications(id, data) {
  return request({
    url: "/notifications/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteNotifications = function deleteNotifications(id) {
  return request({
    url: "/notifications/".concat(id),
    method: 'delete'
  });
};
export var createNotifications = function createNotifications(data) {
  return request({
    url: '/notifications/',
    method: 'post',
    data: data
  });
};
export var getFilters = function getFilters(data) {
  return request({
    url: '/notifications/fetch',
    method: 'post',
    data: data
  });
};