import request from '@/utils/request';
export var defaultReviewData = {
  id: 0,
  enabled: true,
  rating: null,
  subject: '',
  comments: '',
  userId: null,
  user: {
    id: null
  }
};
export var reviewCount = function reviewCount(params) {
  return request({
    url: '/reviews/count',
    method: 'get',
    params: params
  });
};
export var getSubjects = function getSubjects(params) {
  return request({
    url: '/reviews/list-subjects',
    method: 'get',
    params: params
  });
};
export var getReviews = function getReviews(params) {
  return request({
    url: '/reviews',
    method: 'get',
    params: params
  });
};
export var getReviewById = function getReviewById(id) {
  return request({
    url: "/reviews/".concat(id),
    method: 'get'
  });
};
export var updateReview = function updateReview(id, data) {
  return request({
    url: "/reviews/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteReview = function deleteReview(id) {
  return request({
    url: "/reviews/".concat(id),
    method: 'delete'
  });
};
export var createReview = function createReview(data) {
  return request({
    url: '/reviews/',
    method: 'post',
    data: data
  });
};