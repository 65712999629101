import request from '@/utils/request';
export var defaultHolidayData = {
  id: 0,
  enabled: true,
  name: '',
  year: null,
  holidayDate: undefined
};
export var getHolidays = function getHolidays(params) {
  return request({
    url: '/holidays',
    method: 'get',
    params: params
  });
};
export var getHolidayById = function getHolidayById(id) {
  return request({
    url: "/holidays/".concat(id),
    method: 'get'
  });
};
export var updateHoliday = function updateHoliday(id, data) {
  return request({
    url: "/holidays/".concat(id),
    method: 'patch',
    data: data
  });
};
export var deleteHoliday = function deleteHoliday(id) {
  return request({
    url: "/holidays/".concat(id),
    method: 'delete'
  });
};
export var createHoliday = function createHoliday(data) {
  return request({
    url: '/holidays/',
    method: 'post',
    data: data
  });
};